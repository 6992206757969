
// components/EnrollButton.js
"use client";
import ContactUsForm from '@/app/contactus/contactus-form';
import { useState } from 'react';

const EnrollButton = () => {




  const handleOnClick = () =>{
    window.location.href = '/contactus';
  }

 

  return (
    <div>
      <button
        className="bg-gradient-to-r from-teal-400 to-teal-600 text-white font-bold py-2 px-4 rounded-lg"
        onClick={handleOnClick}
      >
        ENROLL NOW
      </button>

     
          
    </div>
  );
};

export default EnrollButton;