"use client";

import React from "react";
import { Typography } from "@material-tailwind/react";
import {
  ChartPieIcon,
  CloudArrowDownIcon,
  CloudIcon,
  Cog6ToothIcon,
  GiftTopIcon,
  KeyIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";


import { BookOpenIcon, GiftIcon, UserIcon } from "@heroicons/react/24/outline";
import { ImageBackgroundCard } from "@/components/image-bg-card";
import Card from "@material-tailwind/react/components/Card";

interface OptionProps {
  icon: React.ElementType;
  title: string;
  children: React.ReactNode;
}

function Option({ icon: Icon, title, children }: OptionProps) {
  return (
    <Card className="pt-5 pb-5 pl-5 hover:bg-teal-100  transform hover:scale-105 transition duration-300 animate-fade-down duration-500 " color="transparent"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
    <div className="flex gap-4">
      <div className="mb-4">
        <Icon className="teal-900 h-8 w-8"  color="teal" />
      </div>
      <div>
        <Typography variant="h5" color="blue-gray" className="mb-2 transform hover:scale-105 transition duration-300"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {title}
        </Typography>
        <Typography className="mb-2 md:w-10/12 font-normal !text-black-500 transform hover:scale-105 transition duration-300"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {children}
        </Typography>
      </div>
    </div>
    </Card>
  );
}

export function WhyChooseUs() {
  return (
    <section className="w-full max-w-4xl mx-auto flex flex-col items-center px-4 py-10 animate-fade-down duration-500   ">
      <Typography variant="h2" className="text-center mb-2" color="blue-gray"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        Why choose our course?
      </Typography>
      <Typography
        variant="lead"
        className="mb-16 w-full text-center font-normal !text-black-500 lg:w-10/12"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        Discover the unique advantages, benefits, and standout features that set
        our course apart from the rest.
      </Typography>
      <div className="mt-4">
    
        <div className="grid grid-cols-1 items-center md:grid-cols-2 gap-4 mb-6">
        
          <ImageBackgroundCard image="Comprehensive Packages">
          We offer comprehensive caregiver training packages tailored to levels 1 through 5, 
          ensuring that our trainees are equipped with the expertise needed to meet the
           diverse needs of aging individuals.
          </ImageBackgroundCard>
          
          <div className="space-y-4">
            <div className="my-4">
              <Option icon={GiftIcon} title="Comprehensive Packages">
              We offer comprehensive caregiver training packages tailored to levels 1 through 5, 
          ensuring that our trainees are equipped with the expertise needed to meet the
           diverse needs of aging individuals.
              </Option>
            </div>
            <div className="mb-4 flex gap-4">
              <Option icon={BookOpenIcon} title="Curriculum and Contents">
              Our curriculum covers a wide range of topics, including basic caregiving techniques,
               specialized care for individuals with conditions such as Alzheimer’s,
                Parkinson’s, dementia, and more
              </Option>
            </div>
            <Option icon={UserIcon} title="Personalized Career Guidence">
            We believe in guiding our trainees towards fulfilling and rewarding careers in elderly care. 
            Through personalized career guidance and support,
             we help individuals navigate their professional paths with confidence and purpose
            </Option>
          </div>
        </div>
        {/* <div className="grid grid-cols-1 items-center md:grid-cols-2 gap-12 mb-24">
          <div className="space-y-8">
            <div className="my-4">
              <Option icon={KeyIcon} title="Routing with React Router">
                Create single-page applications (SPAs) with seamless navigation
                using React Router.
              </Option>
            </div>
            <div className="mb-4 flex gap-4">
              <Option icon={UsersIcon} title="Handling Forms">
                Master form handling in React and manage user input effectively.
              </Option>
            </div>
            <Option icon={CloudArrowDownIcon} title="State Management">
              Explore state management options, including local component state
              and global state using Redux or Context API.
            </Option>
          </div> */}
          {/* <BackgroundCard title="Supportive Community">
            Connect with fellow learners, share experiences, and get support
            from instructors and peers.
          </BackgroundCard> */}
        {/* </div> */}
      </div>
    </section>
  );
}

export default WhyChooseUs;
