

"use client";

import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Typography, Carousel } from "@material-tailwind/react";



export function CarouselFeatures() {

  const testimonials = [
    {
      quote: "Outstanding course contents and experienced tutor.",
      name: "Rija Khadki",
      title: "Care Giver Student.",
      image: "/image/subina.jpeg",
    },
    {
      quote: "Fantastic course with great course content. I have learned so much in such a short period.",
      name: "Subina Shrestha",
      title: "Care Giver Student.",
      image: "/image/rija.jpeg",
    },
    {
      quote: "I am very much satisfied with their course and teaching method.",
      name: "Arju Dhungana",
      title: "Care Giver Student.",
      image: "/image/arju.jpeg",
    },
    {
      quote: "Good training center. I joined level 1 certification course and got job",
      name: "Sarita Giri",
      title: "Care Giver Student.",
      image: "/image/sarita.jpeg",
    },
    // Add more testimonial objects here
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="px-8 pt-10 pb-10 w-full  bg-gray-100">
      <div className="flex mb-16 flex-col items-center">
        <Typography variant="h2" className="text-center mb-2" color="blue-gray"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          What Students Say
        </Typography>
        <Typography
          variant="lead"
          className="mb-3 w-full text-center font-normal !text-black-500 lg:w-10/12"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          Discover what our students have to say about our course!
        </Typography>
      </div>
      

      <div className="container mx-auto !rounded-lg bg-teal-700 bg-no-repeat bg-auto   opacity-90 py-10 lg:px-16 relative bg-[url('/image/logo_tp.png')]  py-10 lg:px-10 relative bg-grey-600" >
        <div className="relative">
          {testimonials.map((testimonial, i) => (
            <div
              key={i}
              className={`transition-opacity duration-1000 ${
                activeIndex === i ? "opacity-100" : "opacity-0"
              } ${activeIndex === i ? "block" : "hidden"}`}
            >
              <div className="!relative flex grid-cols-1 flex-col-reverse gap-6 px-10 py-14 md:grid md:grid-cols-5 md:gap-14 md:py-20">
                <div className="col-span-3 flex flex-col items-start justify-center">
                <Typography
                    variant="lead"
                    color="white"
                    className="mb-5 text-xl font-bold max-w-prose relative before:content-['“'] after:content-['”'] before:text-2xl after:text-2xl before:opacity-60 after:opacity-60 before:mr-1 after:ml-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
  {testimonial.quote}
</Typography>
                  <Typography
                    variant="small"
                    color="white"
                    className="font-medium uppercase"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                  >
                    {testimonial.name},{" "}
                    <span className="font-normal opacity-60">
                      {testimonial.title}
                    </span>
                  </Typography>
                </div>
                <div className="col-span-2 flex w-full shrink-0 md:!justify-end">
                <div className="relative rounded-full overflow-hidden border-4 border-white w-48 h-48">
  <Image
    width={192}
    height={192}
    src={testimonial.image}
    alt={`${testimonial.name} testimonial image`}
    className="object-cover w-full h-full"
  />
</div>
                </div>
              </div>
            </div>
          ))}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {testimonials.map((_, index) => (
              <span
                key={index} // Ensure each dot has a unique key
                className={`block h-1 w-10 cursor-pointer transition-all ${
                  activeIndex === index ? "bg-white" : "bg-white/50"
                }`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CarouselFeatures;



