import React from "react";

import { Typography } from "@material-tailwind/react";

interface ImageBackgroundCardProps {
  image: string;
  children: React.ReactNode;
}

export function ImageBackgroundCard({ image, children }: ImageBackgroundCardProps) {
  return (
    <div className="grid place-items-center h-full bg-white rounded-xl">
    <div className="grid grid-cols-2 gap-4">
      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/2.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>
      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/3.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>
      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/4.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>
      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/5.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>

      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/group2.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>
      <div className="rounded-xl lg:mb-0 mb-12 shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition duration-300">
        <img src="/image/student2.jpeg" className="w-full h-full object-cover border-4 border-white" style={{ borderRadius: '8px', borderWidth: '10px', borderColor: 'white' }} />
      </div>

    </div>
  </div>
  
  );
}
export default ImageBackgroundCard;