"use client";

import React from "react";
import Image from "next/image";
import { Typography } from "@material-tailwind/react";
import {
  AcademicCapIcon,
  CheckBadgeIcon,
  InboxIcon,
} from "@heroicons/react/24/solid";

import FeatureCard from "@/components/feature-card";
import Card from "@material-tailwind/react/components/Card";



export function OnlineCourse() {

  const FEATURES = [
    {
      icon: InboxIcon,
      title: "Comprehensive Caregiver Training Packages",
      description:
        "Are you passionate about providing compassionate care to the elderly? Our comprehensive caregiver training packages are designed to equip you with the essential skills and knowledge needed to excel in the field of elderly care.",
    },
    {
      icon: AcademicCapIcon,
      title: "Personalized Career Guidance and Support",
      description:
        "Ready to take the next step in your career in elderly care? Our personalized career guidance and support services are here to help you navigate your professional journey with confidence.",
    },
    {
      icon: CheckBadgeIcon,
      title: "Specialized Elderly Care Services:",
      description: "Looking for specialized care for yourself or a loved one? Our Elderly Care Services at Hope International are designed to provide compassionate and personalized support for seniors facing a variety of age-related challenges.",
    },
  ];

  
  return (

    <section className="py-28 px-8 bg-[url('/image/dot-pattern.png')]  bg-left-top bg-no-repeat">
      <div className="container mx-auto grid grid-cols-1 place-items-center lg:grid-cols-3 animate-fade duration-500 ">
        {/* <div className="col-span-1 rounded-xl lg:mb-0 mb-12">
          <Image
            width={768}
            height={500}
            src="/image/image1.jpeg"
            className="h-full max-h-[500px] w-full object-cover scale-110"
            alt="online course"
          />
        </div> */}
        <div className="col-span-3 lg:pr-10  ">
          <Typography variant="h2" color="black" className="mb-4 animate-fade duration-500 text-center"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
          Our Special Services
          </Typography>
          <Typography
            variant="lead"
            className="mb-16  w-full text-center font-normal !text-black-500 lg:w-10/12 "  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Learn from experienced professionals who are passionate about sharing their knowledge and expertise in elderly care.
          </Typography>
          

          <div className="col-span-2 grid grid-cols-1 gap-10 sm:grid-cols-3 animate-fade duration-500 mt-10">
            {FEATURES.map(({ icon, title, description }, id) => (
               <Card key={id} className="px-6 pb-5 hover:bg-teal-100 cursor-pointer animate-fade duration-500"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            
              <FeatureCard key={title} icon={icon} title={title}>
                {description}
              </FeatureCard>
          
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
 
  );
}

export default OnlineCourse;
