"use client";

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button } from '@material-tailwind/react';
import EnrollButton from '@/components/enroll-button';

const carouselImages = [
  '/image/group.jpeg',
  '/image/img10.jpeg',

];

export default function Hero() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    startSlideShow();
    return () => clearInterval(slideInterval.current);
  }, []);

  const startSlideShow = () => {
    slideInterval.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === carouselImages.length - 1 ? 0 : prevSlide + 1));
    }, 3000);
  };



  const handlePrevClick = () => {
    clearInterval(slideInterval.current);
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? carouselImages.length - 1 : prevSlide - 1));
    startSlideShow();
  };

  const handleNextClick = () => {
    clearInterval(slideInterval.current);
    setCurrentSlide((prevSlide) => (prevSlide === carouselImages.length - 1 ? 0 : prevSlide + 1));
    startSlideShow();
  };

  return (
    <div className="relative min-h-screen w-full">
      <div className="absolute inset-0 h-full w-full">
        <div
          className="absolute inset-0 transition-opacity duration-500"
          style={{
            backgroundImage: `url(${carouselImages[currentSlide]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 1,
          }}
        />
      </div>
      <div className="absolute inset-0 h-full w-full bg-gray-900/50" />
      <div className="grid min-h-screen px-8">
        <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
          <Typography
            variant="h2"
            color="white"
            className="md:max-w-full lg:max-w-3xl animate-fade duration-500"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Hope International Aged Care Training And Elderly Care Center
          </Typography>
          <Typography
            variant="lead"
            color="white"
            className="mt-20 mb-10 w-full md:max-w-full lg:max-w-2xl animate-fade duration-500"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            We offer courses from level 1 to level 5 where we provide theoretical and practical classes. Hurry up! only limited seats are available.
          </Typography>
          <div className="animate-fade-down duration-500">
          <EnrollButton>
            
          </EnrollButton>
          </div>
        </div>
      </div>
      <button
        onClick={handlePrevClick}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 z-20 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
      >
        <svg className="h-6 w-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
        </svg>
      </button>
      <button
        onClick={handleNextClick}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 z-20 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
      >
        <svg className="h-6 w-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </button>
    </div>
  );
}
