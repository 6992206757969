
"use client";

import React from "react";
import Image from "next/image";
import { Button, Typography } from "@material-tailwind/react";
import {
  AcademicCapIcon,
  CheckBadgeIcon,
  EyeIcon,
  InboxIcon,
} from "@heroicons/react/24/solid";

import Card from "@material-tailwind/react/components/Card";
import { CheckIcon } from "@heroicons/react/24/outline";

const CourseList = [
  {
    image: "/image/course1.jpeg",
    title: "Level I to V certification",
  },
  {
    image: "/image/course2.jpeg",
    title: "Child Development Education ",
  },
  {
    image: "/image/course3.jpg",
    title: "Hospitality Management",
  },
  {
    image: "/image/course4.jpeg",
    title: "Disease Condition Package",
  },
  {
    image: "/image/course5.jpg",
    title: "Interview Package ",
  },
  {
    image: "/image/course6.jpg",
    title: "Prevailed Package",
  },
  {
    image: "/image/course7.jpg",
    title: "English Course (IELTS / PTE)",
  },
];


export function OurCoursesOverview() {
    const handleClick = () => {
        window.location.href = '/courses';
      };
   
  return (
    <section className="py-18 items-center bg-gray-100 pt-5 pb-5">
      <div className="container mx-auto grid grid-cols-1 place-items-center gap-10 lg:grid-cols-3 animate-fade duration-500">
        <div className="col-span-3 lg:py-20 items-center">
          <Typography
            variant="h2"
            color="black"
            className="mb-4 animate-fade duration-500 text-center"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Our Courses
          </Typography>
          <Typography
            variant="h6"
            className="mb-4 px-3 text-left text-lg !text-black-500 font-normal lg:px-0 animate-fade duration-500 text-center"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Explore our various courses and make yourself market ready for abroad preparation and jobs.
          </Typography>

          <div className="col-span-2 grid grid-cols-1 sm:grid-cols-4 gap-x-10 mt-20 animate-fade duration-500 justify-center ">
            {CourseList.map(({ image, title }) => (
              <div key={title} className="flex flex-col items-center pr-10 mb-10">
                <div className="relative overflow-hidden border-4 border-teal-500 w-48 h-48 transform hover:scale-105 ">
                  <Image
                    width={192}
                    height={192}
                    src={image}
                    alt={`${title} testimonial image`}
                    className="object-cover w-full h-full"
                  />
                </div>
                <Typography
                  variant="h5"
                  className="mt-4 mb-2 text-center text-lg !text-black-500 font-bold animate-fade duration-500" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  {title}
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-10">
            <Button
              variant="gradient"
              color="teal"
              size="lg"
              onClick={handleClick}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              SEE MORE
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurCoursesOverview;