import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/app/carousel-features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/app/feature-course-main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/app/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/app/online-course.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/app/why-choose-us.tsx");
;
import(/* webpackMode: "eager" */ "/Users/saugatpoudel/Desktop/hope-international-website-source-code/src/components/index.ts");
